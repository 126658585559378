import React from 'react';
import urun from '../../assets/images/ürün.gif';
import siparis from '../../assets/images/sipariş.gif';
import kargo from '../../assets/images/kargo.gif';
import kazan from '../../assets/images/kazan.gif';

const BoxComponent = () => {
    const data = [
        { text: 'Ürünleri Yükle', icon: urun },
        { text: 'Gelen Siparişi Hazırla', icon: siparis },
        { text: 'Kargoya Teslim Et', icon: kargo },
        { text: 'Kazanmaya Başla', icon: kazan },
    ];

    return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6  cursor-pointer ">
            {data.map((item, index) => (
                <div key={index} className="box flex  min-h-[120px]  lg:w-[282px] lg:h-[157px] xs:w-[180px] xs:h-[93.104px] flex-col  justify-center items-center">
                    <img src={item.icon} alt={item.text} className="icon w-[50px] h-[50px] lg:w-[100px] lg:h-[100px] " />
                    <p className='md:text-sm w-full text-center'>{item.text}</p>
                </div>
            ))}
        </div>
    );
};

export default BoxComponent;
