import React from 'react';

const AboutWezzomart = () => {
    return (
        <div className='flex flex-col gap-12'>
            <div className='flex flex-col gap-6 bg-white rounded-2xl p-8 '>
                <div className='flex flex-col justify-center items-center gap-6'>
                    <h1 className='text-3xl font-bold'>Wezzomart Hakkında</h1>
                </div>
                <div className='flex flex-col gap-3'>
                    <p className='text-[#FFA300] text-2xl font-medium'>Pazar yeri & BT Platformu ve Teknoloji Şirketi.</p>
                    <p className=' w-full  font-medium text-[#6D6D6D]'>Wezzomart Global, Irak e-ticaretinde en geniş ürün yelpazelerinden birini sunacak olan uluslararası bir e-ticaret, Fintech ve Lojistik Şirketleri grubudur.
                        Pazar yerimiz, Moda, Ev & Yaşam, Anne & Bebek, Mobilya, Alet-Edevat ve güzellik ürünleri, giyim ve çok daha fazlasını kapsayan bir ürün yelpazesiyle en geniş yelpazede müşteri ihtiyaçları için tek durak noktasıdır. Irak'ta milyonlarca müşterisi olan Türk satıcılarla bağlantı kuracağız ve onlara işlerini geliştirecek araçlar sunacağız. Büyümemizin arkasında, şirket içi bir teknoloji ekibi tarafından yönetilen sürekli inovasyon yatmaktadır.
                    </p>
                    <p className='text-[#FFA300] text-2xl font-medium '>Wezzomart Global'de Teknoloji;
                    </p>
                    <p className='text-[#6D6D6D]'>
                        Şirket içi Wezzomart Teknoloji Laboratuvarımızda 100'den fazla uzman çalışmaktadır. Bu uzmanlar E-Ticaret Platformumuzu, OM (Order Management- Sipariş Yönetimi) VM (Vendor Management- Tedarikçi Yönetimi), PIM (Product Information Management – Ürün Bilgi Yönetimi), WMS (Warehouse Management System – Depo Yönetim Sistemi), ERP (Enterprise Resource Planning – Girişim Kaynak Planlama), yeni ürün ve hizmetlerimizi geliştirmektedirler.

                    </p>
                    <ul className='flex flex-col gap-4 list-disc'>
                        <li> Wezzomart Global Teknoloji Laboratuvarımız günde bir milyondan fazla siparişi işleyebilecek olan e-ticaret altyapımızı desteklemek için depo yönetimini ve sipariş takibini otomatikleştirmektedir ve bu sayede depolarımıza günlük olarak milyonlarca ürünü teslim etmeyi planlıyoruz.</li>
                        <li>Yeni nesil teknoloji liderlerini yetiştirmenin önemine inanıyoruz. Wezzomart, çoğu Wezzomart ekibine katılmaya devam eden yeni teknoloji profesyonelleri için eğitim ve staj programları sunmaktadır.</li>
                        <li>WezzoMart platformunda satıcılar için zaten kolay olan Mikro ihracat süreci daha da kolaylaştırılmıştır. Ürünler ihracat yapılmak üzere WEZZOMART GLOBAL ELEKTRONİK HİZMETLER ANONİM ŞİRKETİ firmasına yurtiçinde KDV’li olarak fatura edilerek, WezzoMart A.Ş.' nin Türkiye'deki Lojistik Merkezine kargo ile gönderilir. Tüm ihracat süreçleri WezzoMart tarafından organize edilir. Böylece hiçbir satıcı gümrükleme süreçlerine zaman ayırmak zorunda kalmaz.</li>

                        <li>Hali hazırda eğer Türkiye'de yer alan online pazaryerlerinden birisini kullanıyor iseniz, XML veya API entegrasyonu ile bizim sistemimize ürünleri aktarmanız oldukça kolay olacak ve zaman kaybınız minimuma inecektir.</li>

                        <li>Satıcılarımızın tek sorumluluğu siparişini aldıkları ürünleri Türkiye'de TTK'ya göre kayıtlı şirketimize KDV'li fatura keserek ürünlerini WezzoMart deposuna ulaştırmaktır. Sonraki tüm Mikro İhracat (e-İhracat) ve yurt dışı kargo süreçleri WezzoMart Global Elektronik Hizmetler A.Ş. tarafından gerçekleştirilecektir.</li>
                    </ul>
                </div>
            </div>
            <div className='flex  lg:flex-row flex-col gap-6'>
                <div className='flex flex-col gap-6 bg-white rounded-2xl p-8 w-full '>
                    <div className='flex flex-col justify-center text-center items-center gap-6'>
                        <p className='text-2xl font-bold flex '>Lojistik ve Teslimat;</p>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <ul className='flex flex-col gap-4 list-disc'>
                            <li>T.C. Ulaştırma Bakanlığı Lojistik ve Nakliye İdaresine kayıtlıdır.</li>
                            <li>Mikro-İhracatlar konusunda yetki için Türkiye Ticaret Bakanlığı’na kayıtlıdır..</li>
                            <li>Kendi kargo kamyonlarına sahiptir.</li>
                            <li>2'si Türkiye'de 2'si Irak'ta olmak üzere 4 adet lojistik merkezi</li>
                            <li>Irak'ta 11 teslimat noktası.</li>
                            <li>Perakende sektöründe Türkiye'den Irak'a ilk online kapıdan kapıya teslimat.</li>
                            <li>Verimlilik ve çalışan güvenliğine odaklanmış yüksek teknolojili sipariş karşılama merkezleri.</li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-6 bg-white rounded-2xl p-8 w-full '>
                    <div className='flex flex-col justify-center items-center gap-6'>
                        <p className='text-2xl font-bold'>Neden <span className='text-[#FFA300]'>Wezzomart?</span> </p>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <ul className='flex flex-col gap-4 list-disc'>
                            <li>Gerekli Yazılım Altyapısı.</li>
                            <li>Türkiye Lojistik Merkezi ve Fulfillment Deposu.</li>
                            <li>T.C. Ticaret Bakanlığı Karayolu Hızlı Kargo Taşımacılığı Dolaylı Temsil Yetki Belgesi. (Mikro İhracat Yetkisi)</li>
                            <li>Wezzomart’a ait öz-mal Kargo Araçları</li>
                            <li>Irak Mikro İthalat Yetkisi</li>
                            <li>Irak 4 ayrı tahsilat altyapısı entegrasyonu</li>
                            <li>Irak’da müşteri adresine (Kapıya) dağıtım altyapısı
                                Wezzomart Online e-ihracat Pazaryeri Operasyon Süreçleri.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutWezzomart;
