import { Button, Form, Input, notification, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Register from '../../assets/images/register.png';
import Register2 from '../../assets/images/register.png';
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import axios from 'axios';
import kvkk from '../../assets/pdf/kvkk.pdf'

const { Option } = Select;

const RegisterMain = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [checkbox, setCheckbox] = useState(false)
    const [, setIsModalVisible] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const [form] = Form.useForm();


    const showModal = () => {
        setIsModalVisible(true);
    };



    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const turkishCities = [
        'Adana', 'Adıyaman', 'Afyonkarahisar', 'Ağrı', 'Aksaray', 'Amasya', 'Ankara', 'Ardahan', 'Antalya', 'Artvin', 'Aydın', 'Balıkesir',
        'Bartın', 'Batman', 'Bayburt', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır',
        'Düzce', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari',
        'Hatay', 'Iğdır', 'Isparta', 'İstanbul', 'İzmir', 'Kahramanmaraş', 'Karabük', 'Karaman', 'Kars', 'Kastamonu',
        'Kayseri', 'Kırıkkale', 'Kırklareli', 'Kırşehir', 'Kilis', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya', 'Manisa',
        'Mardin', 'Mersin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Osmaniye', 'Rize', 'Sakarya', 'Samsun', 'Siirt',
        'Sinop', 'Sivas', 'Şanlıurfa', 'Şırnak', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Uşak', 'Van', 'Yalova', 'Yozgat', 'Zonguldak'
    ];





    const onFinishFailed = (errorInfo) => {
        notification.error({
            duration: 2,
            message: 'Error',
            description: errorInfo,
        });
    };


    const onFinish = async (values) => {
        const {
            city,
            company,
            email,
            nameSurname,
            sector,
            vendor_name

        } = values;
        if (city === undefined || company === undefined || email === undefined || nameSurname === undefined || sector === undefined || vendor_name === undefined) {
            notification.error({
                duration: 1.5,
                message: "Hata",
                description: "Lütfen tüm alanları doldurun.",
            });
            return;
        }
        const formdata = new FormData();

        formdata.append("city", city);
        formdata.append("company", company);
        formdata.append("email", email);
        formdata.append("nameSurname", nameSurname);
        formdata.append("phone", phoneNumber);
        formdata.append("sector", sector);
        formdata.append("vendor_name", vendor_name);

        try {
            const response = await axios.post('https://api.wezzomart.com/api/v1/apply', formdata);
            const data = response.data;
            console.log(data, 'data')
            notification.success({
                duration: 1.5,
                message: "Başarılı",
                description: "Kayıt işlemi başarılı.",
            });
            form.resetFields();
        } catch (error) {
            notification.error({
                duration: 1.5,
                message: "Hata",
                description: error.response.data.errors,
            });
        }
    };


    return (
        <div className='flex md:flex-row flex-col-reverse justify-center items-center gap-6'>
            <div className='w-full flex gap-6 md:flex-row flex-col-reverse'>
                <div className='flex flex-[1] gap-6 flex-col'>
                    <div className='flex flex-col gap-4' >
                        <p className='p2' >
                            Kayıt Ücreti <span className='text-[#FFA300]'> Yok</span> ,Yurt Dışı Kargo Ücreti <span className='text-[#FFA300]'> Yok</span>, Komisyon <span className='text-[#FFA300]'> Yok</span>.
                        </p>
                        <p className='p3'>Hemen Kayıt Ol <span className='text-[#FFA300]'> Irak'ta Yerini Al</span></p>
                    </div>
                    <Form form={form} layout='vertical' onFinishFailed={onFinishFailed} onFinish={onFinish} className='mt-8 justify-center items-center'>
                        <div className='flex flex-col '>
                            <div className='flex md:grid flex-col grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2'>
                                <Form.Item
                                    name="nameSurname"
                                    label='İsim Soyisim'
                                    required
                                >
                                    <Input
                                        className='border-none h-12'
                                        placeholder='İsim Soyisim'
                                        type="text"
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Telefon'
                                    required
                                >
                                    <PhoneInput
                                        className=" w-full border border-solid bg-white p-2 h-12 "
                                        defaultCountry="TR"
                                        international
                                        value={phoneNumber}
                                        onChange={(text) => setPhoneNumber(text)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label='E-mail'
                                    name="email"
                                >
                                    <Input
                                        className='border-none h-12'
                                        placeholder='E-mail'
                                        type="text"
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label='Firma Adı'
                                    name="company"
                                >
                                    <Input
                                        className='border-none h-12'
                                        placeholder='Firma Adı'
                                        type="text"
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item name="sector"
                                    required
                                    label='Sektör'
                                >
                                    <Select
                                        className='border-none h-12'
                                        placeholder='Sektör'
                                        allowClear
                                    >
                                        <Option value="Kişisel Bakım ve Kozmetik">Kişisel Bakım ve Kozmetik</Option>
                                        <Option value="Kitap ve Yayınevi">Kitap ve Yayınevi</Option>
                                        <Option value="Gıda ve Organik Ürünler">Gıda ve Organik Ürünler</Option>
                                        <Option value="Hırdavat ve Yapı Malzemeleri">Hırdavat ve Yapı Malzemeleri</Option>
                                        <Option value="Tekstil ve Giyim">Tekstil ve Giyim</Option>
                                        <Option value="Takı ve Mücevherat">Takı ve Mücevherat</Option>
                                        <Option value="Otomotiv ve Yedek Parça">Otomotiv ve Yedek Parça</Option>
                                        <Option value="Ofis ve Kırtasiye">Ofis ve Kırtasiye</Option>
                                        <Option value="Bebek ve Çocuk">Bebek ve Çocuk</Option>
                                        <Option value="Sağlık ve Medikal Ürünleri">Sağlık ve Medikal Ürünleri</Option>
                                        <Option value="Ayakkabı">Ayakkabı</Option>
                                        <Option value="Mobilya">Mobilya</Option>
                                        <Option value="Diğer">Diğer</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label='Mağaza Adı / Web Sitesi'
                                    name="vendor_name"
                                    required
                                >
                                    <Input
                                        className='border-none h-12'
                                        placeholder='Mağaza Adı / Web Sitesi'
                                        type="text"
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    name="city"
                                    className='col-span-2'
                                    label='Şehir'
                                >
                                    <Select
                                        className='border-none h-12'
                                        placeholder='Şehir'
                                        allowClear
                                    >
                                        {turkishCities.map(city => (
                                            <Option key={city} value={city}>{city}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <div className="flex gap-1">
                                    <input
                                        type="checkbox"
                                        className="text-orange-400 rounded-sm mt-1"
                                        value={checkbox}
                                        checked={checkbox}
                                        onChange={() => {
                                            setCheckbox((prev) => !prev);
                                        }}
                                    />
                                    <p onClick={showModal} className=" cursor-pointer">
                                        <a href={kvkk} target='_blank' rel="noreferrer" className="text-orange-500 mr-1">Aydınlatma metnini</a>
                                        okudum ve anladım.
                                    </p>
                                </div>
                            </div>
                            <Button disabled={!checkbox} htmlType='submit' className='mb-6 h-2 p-5 mt-6 flex justify-center items-center bg-[#FFA300] rounded-2xl text-white'>
                                Başvuruyu Gönder
                            </Button>
                        </div>
                    </Form>
                </div>
                <div className='hidden md:block md:h-[341px] md:w-[230px] lg:w-96 lg:h-[569px] lg:rounded-[32px] '>
                    <img
                        src={isSmallScreen ? Register2 : Register}
                        alt="register"
                        className=' w-full md:h-[341px] md:w-[230px] lg:w-96 lg:h-[569px] lg:rounded-[32px] md:mt-64 lg:mt-0'
                    />
                </div>
            </div>
        </div >
    );
};

export default RegisterMain;
