import React from 'react'
import iraq from '../../assets/images/iraq.png'
import hedef from '../../assets/images/hedef.png'
import first from '../../assets/images/first.png'


const Information = () => {
    return (
        <div className='flex flex-col gap-12'>
            <div className='flex flex-col md:flex-row gap-10 justify-center items-center '>
                <div className=" w-[328px] md:w-[295px] md:h-[282px] lg:w-[384px] lg:h-[473.151px] ">
                    <img src={first} alt="" />
                </div>
                <div className='flex flex-col mb-4 '>
                    <div>
                        <p className=' text-2xl font-medium mb-2 mx-2'>Sadece Türk marka ve ürünleri için geliştirilmiş “Sınır Ötesi Alışveriş Platformu” WezzoMart Online Pazaryerinin Avantajları; </p>
                        <ul className=''>
                            <li>• Wezzomart Irak Online Pazaryerinde mağaza açmak Ücretsiz ve Kolaydır.</li>
                            <li>• Sistem üzerinden yapacağınız satışlarda pazaryeri komisyon oranı yoktur. (% 0 Komisyon)</li>
                            <li>• Türkiye e-ticaret pazarına ilave olarak yeni bir pazar alanı (Irak) ve müşteri kitlesi sunmaktadır.</li>
                            <li>• WezzoMart Türkiye Lojistik Merkezinden müşteriye (Yurtdışı) Kargo WezzoMart tarafından karşılanır.</li>
                            <li> • Irak İthalat Vergileri WezzoMart Tarafından Karşılanır.</li>
                            <li> • Fatura kestiğiniz taraf Türkiye’deki firmamız (Wezzomart Global A.Ş.)  olacağından tüm e-ihracat Süreçleri WezzoMart tarafından yürütülür.</li>
                            <li>
                                • Tedarikçi ödemeleri 7 gün içerisinde, zamanında ve otomatik banka hesabına yatırılır.
                                WezzoMart Online e-ihracat Pazaryeri İş Modeli.
                                WezzoMart Online Pazaryeri Türk ürün ve markalarını Irak’a perakende olarak ulaştıran güçlü Lojistik (<a href="https://www.anatoliaexpress.com/tr" target='_blank' rel="noreferrer" className='text-blue-400'  >https://www.anatoliaexpress.com/tr</a>)  ve Teknoloji altyapısı (<a href="https://en.wikipedia.org/wiki/Magento" target='_ blank' rel="noreferrer" className='text-blue-400'>https://en.wikipedia.org/wiki/Magento</a>)  ile geliştirilmiş bir e-ihracat iş modelidir.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='flex flex-col-reverse md:flex-row items-center gap-6 w-full'>
                <div>
                    <div>
                        <p className='text-2xl font-medium'>
                            Komşu ülkemiz Irak’ta yeni mobil müşterilere ulaşın ve işletmenizi büyütün.
                        </p>
                    </div>
                    <div className='flex flex-col gap-6 justify-center items-center'>
                        <p className='text-base font-normal'>
                            Türk marka ve ürünler için Irak’ın <a href="https://wezzomart.com/" target='_blank' rel="noreferrer" className='text-[#FFA300]'>”İlk Online Pazaryeri”</a>
                        </p>

                        <p className='text-base font-normal'>
                            Irak’ın en büyük transit lojistik firması ile <a href="https://www.anatoliaexpress.com/tr/" target='_blank' rel="noreferrer" className='text-[#FFA300]'>Anatolia Express</a> mikro ihracat için Irak’a hızlı kargo altyapımızı kullanın
                        </p>
                    </div>
                </div>
                <div>
                    <img src={iraq} alt="iraq" className='w-[328px] h-[218px] md:w-[357px] md:h-[238px] lg:w-[588px] lg:h-[392px] rounded-[32px]' />
                </div>
            </div>
            <div className='flex flex-col md:flex-row justify-around items-center gap-6 mt-10'>
                <div>
                    <img src={hedef} alt="hedef" className='w-[328px] h-[328px] md:w-[357px] md:h-[357px]  lg:w-[486px] lg:h-[486px] rounded-[32px]' />
                </div>
                <div>
                    <p className='text-2xl font-medium mb-4'>
                        Mikro ihracat gönderileriniz için özel hizmetimizi kullanın
                    </p>
                    <p className='text-base font-normal mb-4'>
                        <a href="https://www.anatoliaexpress.com/tr/" target='_blank' rel="noreferrer" className='text-[#FFA300]'>Anatolia Express</a> Mikro ihracat gönderilerinizin tüm aşamalarını kapsayan yeni nesil sınır ötesi lojistik çözümdür
                    </p>
                    <p className='text-2xl font-medium mb-4'>
                        Mikro ihracat ve Hızlı Kargo Çözümümüz ile Sizlere Şunları Sunuyoruz:
                    </p>
                    <ol className='list-disc'>
                        <li>
                            Rekabetçi fiyatlar
                        </li>
                        <li>
                            Teslim alanından son teslimata kadar standartlaştırılmış prosedürler
                        </li>
                        <li>
                            Detaylı kargo takibi
                        </li>
                    </ol>
                </div>

            </div>

        </div>
    )
}

export default Information