import React from 'react'
import '../../index.css'
import BoxComponent from './BoxComponent'




const FirstSection = () => {

    return (
        <div className='flex flex-col justify-center items-center gap-2 mt-48 '>
            <div className='flex flex-col justify-center items-center gap-2'>
                <p className='header text-base md:text-2xl lg:text-4xl'>Türkiye’den Komşu Ülkemiz Irak’a Mikro İhracat Altyapısı İle Kurulan İlk Online Pazaryeri Projesi.</p>
                <a href="https://wezzomart.com/" target='_blank' rel="noreferrer" className='text-[#FFA300] '><span className='w-8 text-md'>
                    wwww.wezzomart.com
                </span> </a>
            </div>
            <div className='flex justify-center items-center  mt-12'>
                <BoxComponent />
            </div>

        </div>
    )
}

export default FirstSection