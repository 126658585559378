import React from 'react';
import yuvarlak from '../../assets/images/yuvarlak.png';
import user from '../../assets/images/user.png';
import araba from '../../assets/images/araba.png';
import para from '../../assets/images/para.png';
import cami from '../../assets/images/cami.png';
import istatik from '../../assets/images/istatik.png';

const Box = () => {
    const data = [
        { text: 'GSYİG : 283 Milyar USD', icon: yuvarlak },
        { text: 'Nüfus 42.2 Milyon Kişi', icon: user },
        { text: 'Yıllık İthalatı 33.68 Milyar USD', icon: araba },
        { text: 'KBGSYİH: 6.696 USD', icon: para },
        { text: 'Başkent Bağdat', icon: cami },
        { text: 'Büyüme Oranı %9.3', icon: istatik },
    ];

    return (
        <div className="grid grid-cols-2 gap-4 ">
            {data.map((item, index) => (
                <div key={index} className="bg-[#F7EFE2]  w-[100px] sm:w-[140px]  md:w-[150px] md:h-[84px] lg:w-[282px] lg:h-[124px] flex flex-col  p-3 justify-center items-center rounded-lg">
                    <img src={item.icon} alt={item.text} className='md:w-[32px] md:h-[32px] lg:w-[64px] lg:h-[64px]  ' />
                    <p className='text-xs md:text-sm lg:text-lg font-medium flex justify-center items-center text-center'>{item.text}</p>
                </div>
            ))}
        </div>
    );
}

export default Box;
