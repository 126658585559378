import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/swiper-bundle.css';
import slider1 from '../../assets/images/1.jpg';
import slider2 from '../../assets/images/2.jpg';
import slider3 from '../../assets/images/3.jpg';
import slider4 from '../../assets/images/4.jpg';
import slider5 from '../../assets/images/5.jpg';
import slider6 from '../../assets/images/6.jpg';
import slider7 from '../../assets/images/7.jpg';
import slider8 from '../../assets/images/8.jpg';
import slider9 from '../../assets/images/9.jpg';
import slider10 from '../../assets/images/10.jpg';
import '../../index.css';

const CarouselImage = () => {
    const sliderImages = [slider1, slider2, slider3, slider4, slider5, slider6, slider7, slider8, slider9, slider10];

    return (
        <div className="carousel-container">
            <Swiper
                spaceBetween={12}
                modules={[Navigation, Scrollbar, A11y, Autoplay]}
                loop={true}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000 }}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                }}
            >
                {sliderImages.map((image, index) => (
                    <SwiperSlide key={index} className='flex'>
                        <img
                            src={image}
                            alt={`Slider ${index + 1}`}
                            className='rounded-[32px]'
                            style={{ objectFit: 'cover' }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-pagination">

            </div>
        </div>
    );
};

export default CarouselImage;
