import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, Button, Form, notification, Drawer } from 'antd';
import Logo from '../assets/logo.png'
import sozlesme from '../assets/pdf/partner.pdf'
import soru from '../assets/pdf/soru.pdf'
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import kvkk from '../assets/pdf/kvkk.pdf'
import riza from '../assets/pdf/riza.pdf'
import { CloseOutlined } from '@ant-design/icons';
import { GiHamburgerMenu } from "react-icons/gi";


import axios from 'axios';

const Header = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [categories, setcategories] = useState([])
    const [setcompany_type, setsetcompany_type] = useState([])
    const [companyTypeSelected, setCompanyTypeSelected] = useState({});
    const [tax_id, settax_id] = useState(null)
    const [form] = Form.useForm();

    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const onCloseDrawer = () => {
        setIsDrawerVisible(false);
    };


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };


    const onFinishFailed = (errorInfo) => {
        notification.error({
            duration: 2,
            message: 'Error',
            description: errorInfo,
        });
    };

    useEffect(() => {
        const fetchCompanyType = async () => {
            try {
                const response = await axios.get('https://api.wezzomart.com/api/v1/public/company-type');
                const data = response.data.data;
                console.log(data, 'data')

                const formattedData = data.map((item) => {
                    let label;

                    switch (item) {
                        case "individual":
                            label = "Şahıs";
                            break;
                        case "general_partnership":
                            label = "Adi Ortaklık";
                            break;
                        case "collective_company":
                            label = "Kollektif Şirket";
                            break;
                        case "ordinary_commandite_partnership":
                            label = "Adi Komandit Şirket";
                            break;
                        case "capital_divided_commandite_company":
                            label = "Sermayesi Paylarla Bölünmüş Komandit Şirket";
                            break;
                        case "limited_company":
                            label = "Limited Şirketi";
                            break;
                        case "joint_stock_company":
                            label = "Anonim Şirket";
                            break;
                        case "cooperative_company":
                            label = "Kooperatif Şirket";
                            break;
                        case "business_partnership":
                            label = "İş Ortaklığı";
                            break;
                        case "association_business":
                            label = "Dernek İşletmesi";
                            break;
                        case "foundation_business":
                            label = "Vakıf İşletmesi";
                            break;
                        case "other":
                            label = "Diğer";
                            break;
                        default:
                            label = "";
                    }
                    return {
                        value: item,
                        label: label
                    }
                })

                setsetcompany_type(formattedData);
            } catch (error) {
                console.error('Error fetching company types:', error);
            }
        };

        fetchCompanyType();
    }, []);

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await axios.get('https://api.wezzomart.com/api/v1/vendor/categories/parent-categories');
                const data = response.data.data;
                console.log(data, 'data')
                const mainCategoriesData = data?.categories.filter(
                    (item) => item.parent_id === null
                );
                setcategories(mainCategoriesData);
            } catch (error) {
                console.error('Error fetching company types:', error);
            }
        };

        getCategories();
    }, []);

    const onFinish = async (values) => {
        const {
            name,
            surname,
            email,
            password,
            company,
            company_type,
            category_id,
            brand_name,
            web_address,
        } = values;

        const formdata = new FormData();

        if (companyTypeSelected === "individual") {
            formdata.append("tc_no", tax_id);
        } else if (tax_id) {
            formdata.append("tax_id", tax_id);
        }

        formdata.append("name", name);
        formdata.append("surname", surname);
        formdata.append("email", email);
        formdata.append("password", password);
        formdata.append("company", company);
        formdata.append("company_type", company_type);
        formdata.append("phone", phoneNumber);
        formdata.append("category_id", category_id);
        formdata.append("brand_name", brand_name);
        formdata.append("web_address", web_address);

        try {
            const response = await axios.post('https://api.wezzomart.com/api/v1/vendor/register', formdata);
            const data = response.data.data;
            console.log(data, 'data')
            handleCancel();
            notification.success({
                duration: 1.5,
                message: "Başarılı",
                description: "Kayıt işlemi başarılı.",
            });
            form.resetFields();
            phoneNumber('');
        } catch (error) {
            if (error?.response?.data?.errors && error?.response?.data?.errors?.password) {
                notification.error({
                    duration: 1.5,
                    message: "Hata",
                    description: "Şifre en az 5 karakter olmalıdır.",
                });
            } else if (error?.response?.data && error?.response?.data?.errors?.email) {
                notification.error({
                    duration: 1.5,
                    message: "Hata",
                    description:
                        "Girdiğiniz e-posta adresi sistemde kayıtlıdır veya e-posta 'wezzomart' içeremez.",
                });
            } else if (error?.response?.data && error?.response?.data?.errors?.tax_id) {
                notification.error({
                    duration: 1.5,
                    message: "Hata",
                    description:
                        "Lütfen geçerli bir vergi numarası giriniz.",
                });
            } else if (error?.response?.data && error?.response?.data?.errors?.tc_no) {
                notification.error({
                    duration: 1.5,
                    message: "Hata",
                    description:
                        "Lütfen geçerli bir TC kimlik numarası giriniz.",
                });
            }
        }
    };





    return (
        <div className='bg-white fixed left-0 right-0 top-0 z-50 p-8 flex justify-center items-center'>

            <div className='flex md:mx-6 lg:mx-36 justify-between container grid-cols-2 '>
                <div>
                    <img src={Logo} alt="" className='w-[157.4px] h-[48px] md:w-[159px] md:h-[45.957px] lg:h-16 lg:w-48' />
                </div>
                <div className="hidden md:flex  justify-center items-center gap-4">
                    <a href={sozlesme} target="_blank" rel="noreferrer" className="bg-[#FFA300] md:w-[129px] md:h-[32px] lg:w-[154px] lg:h-10  header-button">
                        Sözleşme İndir
                    </a>
                    <a href={soru} target="_blank" rel="noreferrer" className="bg-[#FFA300] cursor-pointer md:w-[72px] md:h-[32px] lg:w-[74px] lg:h-10 header-button">
                        S.S.S
                    </a>
                    <a href="https://panel.wezzomart.com/register" target="_blank" rel="noreferrer" className="bg-[#FFA300] md:w-[98px] md:h-[32px] lg:w-[127px] lg:h-10 header-button cursor-pointer">
                        Mağaza Aç
                    </a>
                    <div className="divider hidden lg:block"></div>
                    <a href='https://panel.wezzomart.com' target="_blank" rel="noreferrer" className="border-orange-300 bg-[#FFA300]  bg-opacity-20 border cursor-pointer md:w-[98px] md:h-[32px] lg:w-[109px] lg:h-10  flex justify-center items-center rounded-xl text-[#FFA300]">
                        Giriş Yap
                    </a>
                    <p className="text-[#FFA300] cursor-pointer" onClick={showModal}>
                        Kayıt Ol
                    </p>
                </div>
            </div>
            <div className="md:hidden flex overflow-y-hidden  justify-between items-center">
                <Drawer title="Wezzomart" placement="right" onClose={onCloseDrawer} open={isDrawerVisible}>
                    <div className='flex flex-col justify-center text-center w-full gap-4'>
                        <a href={sozlesme} target="_blank" rel="noreferrer" className="bg-[#FFA300] cursor-pointer h-10  header-button">
                            Sözleşme İndir
                        </a>
                        <a href={soru} target="_blank" rel="noreferrer" className="bg-[#FFA300] cursor-pointer h-10 header-button">
                            S.S.S
                        </a>
                        <a href="https://panel.wezzomart.com/register" target="_blank" rel="noreferrer" className="bg-[#FFA300]  h-10 header-button cursor-pointer">
                            Mağaza Aç
                        </a>
                        <a href='https://panel.wezzomart.com' target="_blank" rel="noreferrer" className="border-orange-300 bg-[#FFA300] bg-opacity-20 border cursor-pointer  h-10  flex justify-center items-center rounded-xl text-[#FFA300]">
                            Giriş Yap
                        </a>
                        <p className="text-[#FFA300]  cursor-pointer" onClick={() => {
                            showModal()
                            onCloseDrawer()
                        }}>
                            Kayıt Ol
                        </p>
                    </div>
                </Drawer>
                {/* <Button className='w-12 h-16' type="primary" onClick={showDrawer} icon={<MenuOutlined className='w-full h-12 text-black shadow-none' />} /> */}
                <GiHamburgerMenu onClick={showDrawer} className='text-black shadow-none w-10 h-8 ' />
            </div>
            <Modal
                width={800}
                open={isModalVisible}
                onOk={handleOk}
                footer={null}
                closeIcon={<CloseOutlined onClick={handleCancel} style={{ fontSize: '24px' }} />}
            >
                <div className='flex flex-col max-h-[32rem] md:max-h-full overflow-y-auto'>
                    <div className='flex flex-col items-center gap-4'>
                        <img src={Logo} alt="Logo" className="h-16 w-48 mb-4" />
                        <p className="text-2xl font-semibold">Tedarikçi Profilinizi Oluşturun</p>
                    </div>
                    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} className='grid md:grid-cols-2 gap-5 mt-8 '>
                        <Form.Item
                            name="name"
                        >
                            <Input placeholder="Ad" className='h-12' />
                        </Form.Item>
                        <Form.Item
                            name="surname"
                        >
                            <Input placeholder="Soyad" className='h-12' />
                        </Form.Item>
                        <Form.Item
                            name="email"
                        >
                            <Input placeholder="E-Posta" className='h-12' />
                        </Form.Item>
                        <Form.Item
                        >
                            <PhoneInput
                                className=" w-full border border-solid bg-white p-2 rounded-lg h-12"
                                defaultCountry="TR"
                                international
                                value={phoneNumber}
                                onChange={(text) => setPhoneNumber(text)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                        >
                            <Input type='password' placeholder="Şifre" className='h-12' />
                        </Form.Item>
                        <Form.Item
                            name="password_confirmation"
                        >
                            <Input type='password' placeholder="Şifre Tekrar" className='h-12' />
                        </Form.Item>
                        <Form.Item
                            name="company"
                        >
                            <Input placeholder="Firma Adı" className='h-12' />
                        </Form.Item>
                        <Form.Item
                            name='company_type'
                        >
                            <Select style={{ width: '100%' }} className='h-12' options={setcompany_type} onChange={(e) => {
                                setCompanyTypeSelected(e)
                            }} placeholder="Şirket Türü" />

                        </Form.Item>
                        <Form.Item
                            name={companyTypeSelected === 'individual' ? 'tc_no' : 'tax_id'}
                        >
                            <Input
                                value={tax_id}
                                className='h-12'
                                onChange={(e) => {
                                    settax_id(e.target.value)
                                }}
                                placeholder={companyTypeSelected === 'individual' ? 'T.C. Kimlik Numarası' : 'Vergi Numarası'}
                            />
                        </Form.Item>
                        <Form.Item
                            name='category_id'
                        >
                            <Select style={{ width: '100%' }} options={categories.map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))} placeholder="Satılacak Ürün Kategorisi"

                                className='h-12' />
                        </Form.Item>
                        <Form.Item
                            name='brand_name'
                        >
                            <Input placeholder="Marka" className='h-12' />
                        </Form.Item>
                        <Form.Item
                            name='web_address'
                        >
                            <Input placeholder="Web Sitesi" className='h-12' />
                        </Form.Item>

                        <div className='w-full'>
                            <div className='flex flex-col justify-start mt-2 gap-3'>
                                <div className="flex gap-1">
                                    <input
                                        type="checkbox"
                                        className="text-orange-400 rounded-sm mt-1"
                                        value={checkbox}
                                        checked={checkbox}
                                        onChange={() => {
                                            setCheckbox((prev) => !prev);
                                        }}
                                    />
                                    <p onClick={showModal} className=" cursor-pointer">
                                        <a href={kvkk} target='_blank' rel="noreferrer" className="text-orange-500 mr-1">Aydınlatma metnini</a>
                                        okudum ve anladım.
                                    </p>
                                </div>
                                <div className="flex gap-1">
                                    <input
                                        type="checkbox"
                                        className="text-orange-400 rounded-sm mt-1"
                                        value={checkbox2}
                                        checked={checkbox2}
                                        onChange={() => {
                                            setCheckbox2((prev) => !prev);
                                        }}
                                    />
                                    <p className=" cursor-pointer">
                                        <a href={riza} target='_blank' rel="noreferrer" className="text-orange-500 mr-1">Rıza metnini</a>
                                        okudum ve anladım.
                                    </p>
                                </div>
                            </div>
                            <Button
                                disabled={!checkbox || !checkbox2}
                                htmlType='submit'
                                className='mt-4 bg-[#FFA300] w-full h-12 rounded-lg text-white'
                            >
                                KAYIT OL
                            </Button>
                        </div>
                    </Form>

                </div>

            </Modal>
        </div >
    );
};

export default Header;
