import React from 'react'
import Box from './Box'

const WhoAreWe = () => {
    return (
        <div className='flex flex-col justify-center gap-12 items-center'>
            <p className='text-[#FFA300] md:text-2xl lg:text-5xl font-semibold '> Biz Kimiz</p>
            <p className='md:text-base font-normal'>
                Hikayemiz, <a href="https://wezzomart.com/" target='_blank' rel="noreferrer" className='text-[#FFA300]'>Anatolia Express Lojistik Hizmetleri</a> olarak Türkiye’den Iraka en iyi, en uygun fiyata ve yüksek kaliteli kapıdan kapıya lojistik hizmeti sunmak üzere 2018 yılında başlamıştır. Bugün itibariyle Anatolia Express bölgenin en büyük transit lojistik firması konumuna gelmiştir.
            </p>
            <Box />
        </div>
    )
}

export default WhoAreWe