import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';
import youtube1 from '../../assets/images/youtube1.png';
import youtube2 from '../../assets/images/youtube2.png';
import youtube3 from '../../assets/images/youtube3.png';
import youtube4 from '../../assets/images/youtube4.png';
import youtube5 from '../../assets/images/youtube5.png';
import youtube6 from '../../assets/images/youtube6.png';

const Youtube = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('');
    const videoRef = useRef(null);

    const openModal = (videoUrl) => {
        setSelectedVideo(videoUrl);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedVideo('');
        setModalOpen(false);
        if (videoRef.current && videoRef.current.getInternalPlayer) {
            const internalPlayer = videoRef.current.getInternalPlayer();
            if (internalPlayer.pauseVideo) {
                internalPlayer.pauseVideo();
            }
        }
    };

    const iframeUrls = [
        "https://www.youtube.com/embed/Qcwgmf0W2Vc?si=emHo8OUc8weeAQ6B",
        "https://www.youtube.com/embed/smRBk-OjM38?si=JxTO17IYFbEAv62e",
        "https://www.youtube.com/embed/KZL2yMSrPy4?si=9Zty8IgjqEKmwsva",
        "https://www.youtube.com/embed/PsbmLVEshk4?si=Jy-g692yciNuWGkM",
        "https://www.youtube.com/embed/azaaV9ydhhI?si=MdC5MlqA9WGldJJB",
        "https://www.youtube.com/embed/BrlrkT8CHPs?si=GuL4ERnydSBVN7A0"
    ];

    return (
        <div className='flex flex-col w-full gap-6 rounded-lg'>
            <p className='font-semibold text-3xl'>Videolarımız</p>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-6 w-full '>
                {[youtube1, youtube2, youtube3, youtube4, youtube5, youtube6].map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`YouTube Video ${index + 1}`}
                        className='rounded-2xl w-full cursor-pointer'
                        onClick={() => openModal(iframeUrls[index])}
                    />
                ))}
            </div>
            <div className='flex justify-center items-center'>
                <a href='https://www.youtube.com/@wezzomart' target='_blank' rel="noreferrer" className='border-none bg-[#FFA300] bg-opacity-20 border w-[195px] h-10  flex justify-center items-center rounded-xl text-[#FFA300]'>
                    Diğer Videolarımız
                </a>
            </div>
            <Modal
                title="Video"
                visible={modalOpen}
                onCancel={closeModal}
                footer={null}
                width={800}
            >
                <ReactPlayer
                    ref={videoRef}
                    url={selectedVideo}
                    controls
                    autoPlay
                    width='100%'
                    height='400px'
                    className='rounded-2xl'
                />
            </Modal>
        </div>
    );
};

export default Youtube;
