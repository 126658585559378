import React from 'react'
import Country from '../../assets/images/iraqharita.png'
import Box from './Box'

const IraqCountry = () => {
    return (
        <div className='flex flex-col justify-between bg-white md:h-[500px] gap-6 lg:h-[634px] rounded-[32px] p-12' >
            <div className='flex justify-center items-center md:text-2xl lg:text-4xl font-semibold'>
                Irak Ülke Profili
            </div>
            <div className='flex flex-col md:flex-row gap-4 justify-around items-center'>
                <div>
                    <img src={Country} alt="country" className='w-[232px] h-[235px] md:w-[309px] md:h-[311px] lg:w-[416px] lg:h-[420px]' />
                </div>
                <div>
                    <Box />
                </div>
            </div>
        </div>
    )
}

export default IraqCountry