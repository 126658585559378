import Header from "./components/Header";
import FirstSection from "./views/FirstSection/FirstSection";
import CarouselImage from "./views/Carousel/CarouselImage";
import RegisterMain from "./views/RegisterMain/RegisterMain";
import Information from "./views/Information/Information";
import WhoAreWe from "./views/WhoAreWe/WhoAreWe";
import IraqCountry from "./views/IraqCountry/IraqCountry";
import AboutWezzomart from "./views/AboutWezzomart/AboutWezzomart";
import Youtube from "./views/Youtube/Youtube";
import Fırsat from "./views/Fırsat/Fırsat";
import Footer from "./views/Footer/Footer";


function App() {


  return (
    <div className="overflow-y-hidden Appp">
      <Header />
      <div className="flex flex-col gap-20">
        <div className="App mx-2 sm:mx-4 md:mx-12 lg:mx-40 flex flex-col gap-20">
          <FirstSection />
          <CarouselImage />
          <RegisterMain />
          <Information />
          <WhoAreWe />
          <IraqCountry />
          <AboutWezzomart />
          <Youtube />
          <Fırsat />
        </div>
        <Footer />
      </div>

      <a href="https://api.whatsapp.com/send?phone=908509697595" rel="noreferrer" class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
      </a>
    </div>
  );
}

export default App;
