import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={
        {
          "components": {
            "Form": {
              "itemMarginBottom": 4,
              "verticalLabelMargin": 10
            },
            "Select": {
              "borderRadiusXS": 30
            }
          }
        }
      }
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);


