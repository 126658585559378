import React from 'react';

const Box = () => {
    const data = [
        "Misyonumuz, bölgede edindiğimiz tecrübe ve lojistik altyapısı ile ürünlerinizi ve markalarınızı komşu ülkelerimizdeki (öncelikle Irak) müşterilerimize sorunsuz e-ticaret deneyimi sunarak olumlu bir etki yaratmaktır.",
        "Amacımız ülkemiz ürün ve markalarını güçlü lojistik altyapımız ve mikro ihracat aracılığıyla yakın coğrafyaya erişilebilir kılmak.",
        "Hedefimiz, komşu ülkelere Türk ürünlerinin ve markalarının sunulduğu en iyi e-ticaret pazaryeri ve güçlü Lojistik altyapımız ile Mikro ihracatta ülkemizin en büyüğü olmak.",
        "Platformumuzda yer alacak tedarikçilerimizin dijitalleşmesini destekleyerek, yerel üreticileri güçlendirerek ve kadınların dijital ekonomi ve üretime katılımını savunuyoruz."
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full  ">
            {data.map((item, index) => (
                <div key={index} className="bg-white text-base text-[#6D6D6D] rounded-2xl flex flex-col  p-6 justify-center items-center">
                    <div>{item}</div>
                </div>
            ))}
        </div>
    );
}

export default Box;
