import React from 'react'
import Box from './Box'

const Fırsat = () => {
    return (
        <div className='flex flex-col justify-center items-center gap-12'>
            <p className='text-2xl font-bold'>
                Fırsatlar
            </p>
            <div>
                <Box />
            </div>
        </div>
    )
}

export default Fırsat