import React from 'react';
import free from '../../assets/images/free.png';
import yüzde from '../../assets/images/yüzde.png';
import dolaryüzde from '../../assets/images/dolaryüzde.png';
import smiley from '../../assets/images/smiley.png';
import truck from '../../assets/images/truck.png';
const Box = () => {
    const data = [
        { text: 'Ücretsiz Kayıt ve Ürün Listesi', icon: free },
        { text: '0% Komisyon', icon: yüzde },
        { text: '7 Gün İçinde Banka Hesabına Otomatik Ödeme', icon: dolaryüzde },
        { text: 'Sıfır İade Politikası', icon: smiley },
        { text: 'Yurtdışı Kargo Ücreti Yok', icon: truck },
    ];

    return (
        <div className="flex justify-center items-center flex-wrap gap-6 ">
            {data.map((item, index) => (
                <div key={index} className="bg-white rounded-full flex lg:w-[400px] md:w-[320px]  md:flex-row justify-center items-center text-center gap-4">
                    <img src={item.icon} alt={item.text} className="rounded-full bg-orange-400 h-[90px] w-[90px]" />
                    <div className='w-[280px] sm:w-[384px] sm:h-[79px] flex justify-center items-center text-[#6D6D6D]'>
                        {item.text}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Box;
